import indexStyles from '../sass/index.scss';

$(".image-accordion").each(function() {
    var jqMainContainer = $(this);
    var jqSliderCOntainer = $(jqMainContainer.find('.image-accordion-holder').first());
    jqSliderCOntainer.slick({
        mobileFirst: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        variableWidth: true,
        infinite: false,
        responsive: [
            {
                breakpoint: 1279,
                settings: "unslick"
            },
            {
                breakpoint: 1278,
                settings: {
                    slidesToShow: 1,
                    arrows: false,
                    slidesToScroll: 1,
                    infinity: false
                }
            },
            {
                breakpoint: 724,
                settings: {
                    slidesToShow: 1,
                    arrows: false,
                    slidesToScroll: 1,
                    infinity: false
                }
            }
        ]
    })
});